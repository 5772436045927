import React from "react"
import PrivateRoute from "../../../components/auth/PrivateRoute/loadable"
import ProfileRoute from "../../../components/auth/ProfileRoute"
import ProposalList from "../../../components/Proposal/ProposalList"

const ProposalsPage = props => (
  <PrivateRoute url={props.location.href}>
    <ProfileRoute>
      <ProposalList url={props.location.href} />
    </ProfileRoute>
  </PrivateRoute>
)

export default ProposalsPage
